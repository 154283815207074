import axios from "axios";

const BASE_URL = "https://api.matteo-gualdi.it";

export class BarAPI {
  static async fetchAll() {
    return (await axios.get(`${BASE_URL}/bar`)).data;
  }
  static async addBarByName(nome, valore) {
    return (await axios.post(`${BASE_URL}/bar/update`, {nome, valore})).data;
  }
}