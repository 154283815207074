import { useEffect, useState } from "react";
import { BarAPI } from "../../api/bar";
import { BarData } from "../../components/BarData/BarData";
import { toast } from "../../utils/sweet-alert";

export function Bar() {

    const [barData, setBarData] = useState([]);

    async function fetchBarData() {
        try {
          const barDataResp = await BarAPI.fetchAll();
          if (barDataResp.length > 0) {
            setBarData(barDataResp);
          }
        } catch (error) {
          toast("error", "Errore nel recuperare i dati del bar!")
        }
    }

    const handleButtonClick = async (name, incrementValue) => {
      try {
          const barDataResp = await BarAPI.addBarByName(name, incrementValue);
          setBarData(barData => {
            return barData.map(item => {
                if (item.nome === barDataResp.nome) {
                    return barDataResp;
                }
                return item;
            });
        });
      } catch (err) {
          toast("error", err.response.data.detail);
      }
  };

    useEffect(() => {
        fetchBarData();
    }, []);


    return (
        <BarData barData={barData} onClickFunc={handleButtonClick} />
    );
}
