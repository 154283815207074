import dayjs from "dayjs"

export class FormatterService {

  static formatDtToHM(input_dt) {
    return dayjs(input_dt).format("DD/MM/YY HH:MM");
  }

  static formatDtBirthday(input_dt) {
    const birthday = dayjs(input_dt);
    const isOver18 = dayjs().diff(birthday, "years") >= 18;
    const emoji = isOver18 ? "🟢" : "🟡";

    return `${birthday.format("DD/MM/YY")} ${emoji}`;
  }  
  
  static formatUser(input_user) {
    return (
      `<b>Nome:</b> ${input_user.nome}
      <br>
      <b>Cognome:</b> ${input_user.cognome}
      <br>
      <b>Telefono:</b> ${input_user.numero}
      <br>
      <b>Nascita:</b> ${this.formatDtBirthday(input_user.nascita)}`
      );
    }
    
  static formatUserConfirm(input_user) {
      return (
        `<b>Nome:</b> ${input_user.nome}
        <br>
        <b>Cognome:</b> ${input_user.cognome}
        <br>
        <b>Telefono:</b> ${input_user.numero}
        <br>
        <b>Nascita:</b> ${input_user.nascita}
        <br>
        <b>Ingresso:</b> ${this.formatDtToHM(input_user.ts_ingresso)}`
      );
    }
}