import { Stack, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';

export function BarData({ barData, onClickFunc }) {

    const PLUS_ONE = 1;
    const MINUS_ONE = -1;

    const BEVERAGE_DRINK = 'drink';
    const BEVERAGE_SHOT = 'shot';
    const BEVERAGE_SPECIAL = 'special';
    const BEVERAGE_ANALCOLICO = 'analcolico';

    const getValoreByNome = (nome) => {
        console.log(barData)
        const item = barData.find(item => item.nome === nome);
        return item ? item.valore : null;
    };

    return (
        <Stack justifyContent="center" spacing={2} direction="column">
        <Button variant="contained" color="success" startIcon={<AddCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_DRINK, PLUS_ONE)}>
            Drink - {getValoreByNome(BEVERAGE_DRINK)}
        </Button>
        <Button variant="contained" color="success" startIcon={<AddCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_SHOT, PLUS_ONE)}>
            Shot - {getValoreByNome(BEVERAGE_SHOT)}
        </Button>
        <Button variant="contained" color="success" startIcon={<AddCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_SPECIAL, PLUS_ONE)}>
            Special - {getValoreByNome(BEVERAGE_SPECIAL)}
        </Button>
        <Button variant="contained" color="success" startIcon={<AddCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_ANALCOLICO, PLUS_ONE)}>
            Analcolico - {getValoreByNome(BEVERAGE_ANALCOLICO)}
        </Button>
        <Box height={20}></Box> {/* Spazio aggiunto */}
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_DRINK, MINUS_ONE)}>
            Drink - {getValoreByNome(BEVERAGE_DRINK)}
        </Button>
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_SHOT, MINUS_ONE)}>
            Shot - {getValoreByNome(BEVERAGE_SHOT)}
        </Button>
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_SPECIAL, MINUS_ONE)}>
            Specical - {getValoreByNome(BEVERAGE_SPECIAL)}
        </Button>
        <Button variant="contained" color="error" startIcon={<RemoveCircleIcon />} sx={{ height: '8vh', fontSize: '1.5rem' }} onClick={() => onClickFunc(BEVERAGE_ANALCOLICO, MINUS_ONE)}>
            Analcolico - {getValoreByNome(BEVERAGE_ANALCOLICO)}
        </Button>
        </Stack>
    );
}