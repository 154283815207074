import Swal from "sweetalert2";

export async function dialog(type, title, msg) {
  return Swal.fire({
    icon: type,
    html: msg,
    showConfirmButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: "Chiudi",
    title: title,
  });
}

export async function confirmDialog(type, title, msg) {
  return Swal.fire({
    title: title,
    html: msg,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Conferma!',
    cancelButtonText: 'Chiudi'
  })
}

export async function toast(type, msg) {
  return Swal.fire({
    position: "top-end",
    icon: type,
    showConfirmButton: false,
    timer: 3000,
    toast: true,
    title: msg,
  });
}
