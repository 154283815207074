import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export function UtentiTable({ utentiList }) {

    const columns = [
        { field: 'id', headerName: 'ID', hide: true, flex: 1 },
        { field: 'entrata', headerName: 'Entrata', type: 'boolean', minWidth: 120, flex: 1 },
        { field: 'nome', headerName: 'Nome', minWidth: 150, flex: 2 },
        { field: 'cognome', headerName: 'Cognome', minWidth: 150, flex: 2 },
        { field: 'numero', headerName: 'Numero', minWidth: 150, flex: 1 },
        { field: 'anno', headerName: 'Anno', minWidth: 130, flex: 1 },
      ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={
            utentiList.map((utente) => {
                return (
                    {
                        id: utente.id,
                        nome: utente.nome, 
                        cognome: utente.cognome, 
                        numero: utente.numero, 
                        anno: utente.anno, 
                        entrata: utente.entrata
                    }
                )
            })
        }
        columns={columns}
        initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 100 },
            },
            columns: {
                columnVisibilityModel: {
                    id: false
                },
            },
        }}
        slots={{
            toolbar: GridToolbar,
        }}
        slotProps={{
            columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true
            },
        }}
        stickyHeader
      />
    </div>
    );
}