import axios from "axios";

const BASE_URL = "https://api.matteo-gualdi.it";

export class UserAPI {
  static async fetchAll() {
    return (await axios.get(`${BASE_URL}/users`)).data;
  }
  static async validateByUUID(uuid) {
    return (await axios.post(`${BASE_URL}/users/validate`, {uuid})).data;
  }
  static async confirmByUUID(uuid) {
    return (await axios.post(`${BASE_URL}/users/confirm`, {uuid})).data;
  }
  static async fetchById(userId) {
    return (await axios.get(`${BASE_URL}/users/${userId}`)).data;
  }
}