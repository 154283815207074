import { Html5QrcodeScanner } from "html5-qrcode"
import { useEffect } from "react";
import { UserAPI } from "../../api/user";
import { dialog, confirmDialog } from "../../utils/sweet-alert";
import { FormatterService } from "../../utils/formatter";

export function Scanner() {

  useEffect(() => {
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 250,
        height: 250
      },
      fps: 25,
      showTorchButtonIfSupported: true
    });
  
    scanner.render(validateUser, error);

    async function validateUser(readData) {
      scanner.pause(false);
      try {
        const user = await UserAPI.validateByUUID(readData);
        const userResp = await confirmDialog("warning", "Registrare ingresso?", FormatterService.formatUser(user));
        if (userResp.value) {
          const user = await UserAPI.confirmByUUID(readData);
          await dialog("success", "Ingresso registrato", FormatterService.formatUserConfirm(user));
        }
      } catch (err) {
        await dialog("error", "Errore ingresso", err.response.data.detail);
      } finally {
        scanner.resume();
      }
    }
  
    function error(err) {
      console.warn(err);
    }
    
  },[])

  return (
    <div>
      <h1>Controllo Ingressi</h1>
      <div id="reader"/>
    </div>
  );
}
