import { useEffect, useState } from "react";
import { UserAPI } from "../../api/user";
import { UtentiTable } from "../../components/UtentiTable/UtentiTable";
import { toast } from "../../utils/sweet-alert";

export function Utenti() {
    const [utentiList, setUtentiList] = useState([]);

    async function fetchUtenti() {
        try {
          const utentiListResp = await UserAPI.fetchAll();
          if (utentiListResp.length > 0) {
            setUtentiList(utentiListResp);
          }
        } catch (error) {
          toast("error", "Errore nel recuperare la lista di utenti!")
        }
    }

    useEffect(() => {
        fetchUtenti();
    }, []);


    return (
        <UtentiTable utentiList={utentiList}/>
    );
}
